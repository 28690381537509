import theme from './../../../gatsby-plugin-theme-ui';

// PROPS

export const about_company_section_props = {
    sx: {
        width: '100%',
        height: 'auto',
        flexFlow: 'column nowrap',
        margin: '0 0 5rem 0'
    }
}

export const about_company_heading_props = {
    as: 'h1',
    background_text: 'O firmie',
    margin: '2rem auto 3.5rem auto'
}

export const about_company_description_props = marginBottom => ({
    as: 'p',
    fontSize: [16, 16, 18],
    margin: `0 0 ${marginBottom} 0`
})
export const about_company_container_props = margin => ({
    sx: {
        flexDirection: ['column', 'column', 'row'],
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: margin ? margin: null
    }
})

export const about_company_text_props = margin => ({
    as: 'p',
    width: ['100%', '100%', 'calc(50% - 2rem)'],
    fontSize: [16, 16, 18],
    padding: ['0 1.25rem', '0 1.25rem', 0],
    margin: margin ? margin: null
})

export const about_company_image_container_props = alignSelf => ({
    sx: {
        width: ['100%', '100%', 'calc(50% - 1.25rem)'],
        height: [350, 350, 300],
        position: 'relative',
        padding: ['calc(2.5rem + 10px) 1.25rem', 'calc(2.5rem + 10px) 1.25rem', 'calc(1.25rem + 10px)'],
        alignSelf: alignSelf ? alignSelf : null
    }
})

export const about_company_image_props = (data, picture) => ({
    sx: {
        width: '100%',
        height: '100%',
        background: picture ?
        `url(${data.allFile.edges.filter(
            file => file.node.name === picture)[0].node.publicURL}) no-repeat 15% 50% / cover` : theme.colors.grey,
        maxWidth: 450,
        margin: '0 auto',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)'
    }
})

const border_props = (position, width, height, transform, display) => ({
    position, width, height, transform, display
})

export const border_top_props = border_props(
    { top: 0, left: 0 }, '45%', 10,
    null, { display: ['none', 'none', 'inherit'] }
)

export const border_left_props = border_props(
    { top: 0, left: 0 }, ['45%', '45%', 10],
    [10, 10, '45%'], { transform: [null, null, 'rotate(180deg)'] }, null
)

export const border_right_props = border_props(
    { bottom: 0, right: '1px' }, '45%', 10,
    { transform: 'rotate(180deg)' }, { display: ['none', 'none', 'inherit'] }
)

export const border_bottom_props = border_props(
    { bottom: 0, right: 0 }, ['45%', '45%', 10],
    [10, 10, '45%'], { transform: ['rotate(180deg)', 'rotate(180deg)', 'rotate(0deg)'] }, null
)