import React from 'react';
import { Flex, Box } from 'rebass/styled-components';
import { StaticQuery, graphql } from 'gatsby';

// STYLES
import {
  about_company_section_props,
  about_company_heading_props,
  about_company_description_props,
  about_company_container_props,
  about_company_text_props,
  about_company_image_container_props,
  about_company_image_props,
  border_top_props,
  border_left_props,
  border_bottom_props,
  border_right_props
} from './styles';

// COMPONENTS
import Section from './../../atoms/section';
import Heading from './../../atoms/heading';
import Text from './../../atoms/text';
import Border from './../../atoms/border';

export default function aboutCompanySection(props) {

  return (
    <StaticQuery query={
      graphql`
      {
        allFile(filter: { extension: { eq: "jpg" } }) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
      }`
    } render={data => (
      <Section {...about_company_section_props}>
        <Heading {...about_company_heading_props}>Kilka słów o nas...</Heading>
        <Box padding={['0 1.25rem', '0 1.25rem', 0]}
        data-sal='slide-up'
        data-sal-duration="600"
        data-sal-delay="0"
        data-sal-easing="cubic-bezier(0.165, 0.84, 0.44, 1)">
          <Text {...about_company_description_props('2.5rem')}>
            Firma Ardor sp. z o.o. istnieje od <strong>1996 roku.</strong>
            <br></br>
            Przez lata nasza firma nabywała doświadczenie oraz zdobywała zaufanie, 
            zarówno klientów, jak i sprzedawców.<br></br>Obecnie utrzymujemy kontakty handlowe 
            z producentami z Niemiec, Norwegii, Czech i Polski.
          </Text>
          <Text {...about_company_description_props('5rem')}>
            Świadczymy wysokiej jakości usługi w zakresie sprzedaży oraz budowy kominków, 
            pieców kominkowych, piecyków na pellet, urządzeń na bio paliwo, kominków elektrycznych, 
            kominów oraz systemów ogrzewania. Dzięki zatrudnianiu kadry o wieloletnim doświadczeniu 
            oraz systematycznym szkoleniom utrzymujemy standardy przewyższające konkurencję. 
            Nasze kwalifikacje oraz umiejętności zostały potwierdzone licznymi nagrodami, 
            dyplomami oraz państwowym certyfikatem Polskiej Izby Rzemieślniczej nadającej 
            tytuł Mistrza Zduńskiego. Jako firma, jesteśmy również honorowym założycielem 
            Stowarzyszenia „Kominki Polskie”, w życiu którego aktywnie uczestniczymy od ponad 10 lat.
          </Text>
        </Box>
        <Flex {...about_company_container_props([0, 0, '0 0 5rem 0'])}>
          <Box {...about_company_image_container_props(null)}
          data-sal='slide-right'
          data-sal-duration="600"
          data-sal-delay="0"
          data-sal-easing="cubic-bezier(0.165, 0.84, 0.44, 1)">
            <Border {...border_left_props} />
            <Border {...border_top_props} />
            <Border {...border_bottom_props} />
            <Border {...border_right_props} />
            <Box {...about_company_image_props(data, 'about_pic_01')} />
          </Box>
          <Text {...about_company_text_props(['5rem 0 2.5rem 0', '5rem 0 2.5rem 0', 0])}
          animation={{
          'data-sal': 'slide-left',
          'data-sal-duration': '600',
          'data-sal-delay': '0',
          'data-sal-easing': 'cubic-bezier(0.165, 0.84, 0.44, 1)'
          }}>
            Nasz salon to ponad 100 metrów kwadratowych przestrzeni ekspozycyjnej, 
            co pozwala nam zaprezentować Państwu wiele ciekawych urządzeń. 
            Znajdą u nas Państwo zarówno wolno stojące piecyki na drewno i na pellet, 
            jak i wkłady w przykładowych zabudowach. Wśród nich znajdą Państwo ciekawe 
            portale oraz kominki w zabudowie kaflowej- w czym szczególnie się specjalizujemy.
          </Text>
        </Flex>
        <Flex {...about_company_container_props(null)}>
          <Text {...about_company_text_props(['0 0 5rem 0', '0 0 5rem 0', 0])}
          animation={{
            'data-sal': 'slide-left',
            'data-sal-duration': '600',
            'data-sal-delay': '0',
            'data-sal-easing': 'cubic-bezier(0.165, 0.84, 0.44, 1)'
          }}>
            Ponadto na wejściu przywita Państwa ciepło z prawdziwego, 
            podłączonego kominka. Poza tradycyjnym kominkiem, 
            mamy również zainstalowane inne urządzenia: piecyk na pellet, 
            biokominek oraz kominek elektryczny, dzięki czemu zarówno w teorii, 
            ale również w praktyce możemy przedstawić ich działanie.
          </Text>
          <Box {...about_company_image_container_props([null, null, 'flex-end'])}
          data-sal='slide-right'
          data-sal-duration="600"
          data-sal-delay="0"
          data-sal-easing="cubic-bezier(0.165, 0.84, 0.44, 1)">
            <Border {...border_left_props} />
            <Border {...border_top_props} />
            <Border {...border_bottom_props} />
            <Border {...border_right_props} />
            <Box {...about_company_image_props(data, 'about_pic_02')} />
          </Box>
        </Flex>
      </Section>
    )} />
  );
}
